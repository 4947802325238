<main dir="rtl">
  <div><span> </span></div>
</main>

<style>
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #100d17;
    text-align: start;
  }

  li {
    color: #ffffff;
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  a {
    color: #ffffff;
    transition: opacity 300ms ease-in-out;
    text-decoration: none;
  }

  ul {
    width: fit-content;
    list-style: inside "–  ";
  }

  a:hover {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    main {
      padding: 0 2rem;
    }
  }
</style>
