<script>
  window.location.href = "https://waelalawfi.com/bio";
</script>
<!-- <script>
  import Fa from "svelte-fa";
  import {
    faInstagram,
    faYoutube,
    faTelegram,
  } from "@fortawesome/free-brands-svg-icons";

  export const socials = [
    {
      icon: faYoutube,
      link: "https://youtube.com/@user-fl6ro2uo5w",
    },
    {
      icon: faInstagram,
      link: "https://instagram.com/wael_qassem",
    },
    {
      icon: faTelegram,
      link: "https://t.me/wail22",
    },
  ];
</script>

<main>
  <img src="/wael.jpg" alt="الاستاذ وائل العوفي" width="128" height="128" />
  <h1>الاستاذ وائل العوفي</h1>
  <p>الأحياء</p>

  <div class="socials">
    {#each socials as social}
      <a href={social.link} target="_blank" rel="noreferrer noopener">
        <Fa icon={social.icon} fw />
      </a>
    {/each}
  </div>
</main>

<style>
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000a11;
    color: #ffffff;
  }
  img {
    margin-bottom: 1rem;
    border-radius: 100%;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    padding: 0;
    text-align: center;
  }
  p {
    margin: 0;
    opacity: 0.7;
    text-align: center;
  }
  .socials {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
  }
  .socials a {
    display: inline-flex;
    align-items: center;
    opacity: 0.7;
    font-size: 2rem;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
  }
  .socials a:hover {
    color: #0b9dff;
    opacity: 1;
    transform: scale(1.2);
  }

  @media (max-width: 768px) {
    main {
      padding: 0 2rem;
    }
  }
</style> -->
