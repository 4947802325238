<script>
  import router from "page";
  import Home from "./Home.svelte";
  import Wael from "./Wael.svelte";
  import Baqer from "./Baqer.svelte";
  import Zeyad from "./Zeyad.svelte";

  let page;

  router("/", () => (page = Home));
  router("/wael", () => (page = Wael));
  router("/baqer", () => (page = Baqer));
  router("/zeyad", () => (page = Zeyad));
  router("*", () => router.redirect("/"));

  router.start();
</script>

<svelte:component this={page} />
